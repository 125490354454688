export default [
  {
    title: 'breadcrumb.administrator',
    icon: 'la-user-shield',
    iconType: 'line-awesome',
    children: [
      {
        title: 'breadcrumb.user',
        route: 'list-user',
        icon: '',
        resource: 'user',
        action: 'read'
      },
      {
        title: 'breadcrumb.rolePermission',
        route: 'list-role-permission',
        icon: '',
        resource: 'role-permission',
        action: 'read'
      },
    ],
  },
];