export default [
  {
    title: "breadcrumb.objective",
    icon: "GridIcon",
    children: [
      {
        title: "breadcrumb.objectiveType",
        route: "list-objective-type",
        icon: "",
        resource: "objective-type",
        action: "read",
      },
      {
        title: "breadcrumb.objective",
        route: "list-objective",
        icon: "",
        resource: "objective",
        action: "read",
      },
    ],
  },
];
