import dashboard from "./menu/dashboard";
import configurationHeader from "./menu/configurationHeader";
import user from "./menu/user";
import company from "./menu/company";
import department from "./menu/department";
import position from "./menu/position";
import okrSettingHeader from "./menu/okrSettingHeader";
import objectiveType from "./menu/objectiveType";
import midYearReview from "./menu/midYearReview";
import yearEndReview from "./menu/yearEndReview";
import okrSetting from "./menu/okrSetting";
import stepInformation from "./menu/stepInformation";
import employee from "./menu/employee";
import configuration from "./menu/configuration";

export default [
  ...dashboard,
  ...okrSettingHeader,
  ...objectiveType,
  ...midYearReview,
  ...yearEndReview,
  ...stepInformation,
  ...okrSetting,
  ...configurationHeader,
  ...company,
  ...department,
  ...position,
  ...employee,
  ...user,
  ...configuration,
]
