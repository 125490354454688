export default [
  {
    title: "breadcrumb.yearEndReview",
    icon: "CalendarIcon",
    children: [
      {
        title: "breadcrumb.status",
        route: "list-year-end-review-status",
        icon: "",
        resource: "year-end-review-status",
        action: "read",
      },
      {
        title: "breadcrumb.commentQuestion",
        route: "list-year-end-review-comment-question",
        icon: "",
        resource: "year-end-review-comment-question",
        action: "read",
      },
      {
        title: "breadcrumb.considerQuestion",
        route: "list-year-end-review-consider-question",
        icon: "",
        resource: "year-end-review-consider-question",
        action: "read",
      },
      {
        title: "breadcrumb.signatureLabel",
        route: "list-year-end-review-signature-label",
        icon: "",
        resource: "year-end-review-signature-label",
        action: "read",
      },
    ],
  },
];
