export default [
  {
    title: "breadcrumb.midYearReview",
    icon: "CalendarIcon",
    children: [
      {
        title: "breadcrumb.status",
        route: "list-mid-year-review-status",
        icon: "",
        resource: "mid-year-review-status",
        action: "read",
      },
      {
        title: "breadcrumb.commentQuestion",
        route: "list-mid-year-review-comment-question",
        icon: "",
        resource: "mid-year-review-comment-question",
        action: "read",
      },
    ],
  },
];
